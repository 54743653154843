import React, { FC } from "react"
import { Layout } from "antd"

import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"
import PromotionFeaturedBanner from "./promotion/featured-banner"

const { Header } = Layout

const HeaderWrapper: FC<{ isFixed?: boolean }> = ({ isFixed }) => {
  return (
    <Header id="header" style={{ backgroundColor: "white", padding: 0 }}>
      {/* <PromotionFeaturedBanner /> */}
      <DesktopMenu isFixed={isFixed} />
      <MobileMenu placement="top" isFixed={isFixed} />
    </Header>
  )
}
export default HeaderWrapper
