import React, { FC, useState } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { useLocation } from "@reach/router"
import { Button, Space, Typography } from "antd"
import _ from "lodash"
import { PhoneOutlined } from "@ant-design/icons"

import "../styles/hamburgers/hamburgers.scss"
import "../styles/nav.scss"

const splitPathname = pathname => {
  if (pathname === "/") {
    return ["/"]
  }

  return _.drop(_.split(pathname, "/"))
}

const { Title, Paragraph } = Typography

const DesktopMenu: FC<{ isFixed: boolean }> = ({ isFixed }) => {
  const location = useLocation()
  const [currentActive, setCurrentActive] = useState(
    splitPathname(location.pathname)
  )
  const [activeMegaMenu, setActiveMegaMenu] = React.useState("")
  const [dropDownIconAngle, setDropDownIconAngle] = useState(0)

  React.useEffect(() => {
    if (window) {
      window.onclick = e => {
        if (
          !e.target.classList.contains("mega-menu") &&
          !e.target.classList.contains("mm-dropdown-btn") &&
          activeMegaMenu !== ""
        ) {
          setActiveMegaMenu("")
          setDropDownIconAngle(0)
        }
      }
    }

    const setMenuPosition = () => {
      if (window.pageYOffset > 20) {
        document
          .getElementById("main-menu-container")
          .classList.add("position-fixed")
      } else {
        document
          .getElementById("main-menu-container")
          .classList.remove("position-fixed")
      }
    }

    if (isFixed) {
      window.addEventListener("scroll", setMenuPosition)

      return () => {
        window.removeEventListener("scroll", setMenuPosition)
      }
    }
  }, [])

  // handle megamenu click on desktop view
  const handleMegaMenuClick = React.useCallback(
    e => {
      const { name } = e.currentTarget.dataset
      if (activeMegaMenu === "") {
        setActiveMegaMenu(name)
        setDropDownIconAngle(180)
      } else {
        setActiveMegaMenu("")
        setDropDownIconAngle(0)
      }
    },
    [activeMegaMenu]
  )

  return (
    <div id="main-menu-container" className="shadow-sm">
      <div className="nav-bar-container">
        {/* LOGO SECTION */}
        <div>
          <Link to="/">
            <Title level={2} className="text-primary">
              NAILS LOUNGE BY THE SEA
            </Title>
          </Link>
        </div>

        {/* MAIN MENU */}
        <ul className="nav-bar">
          <li className="nav-bar-item">
            <Link
              className={classNames([
                "nav-bar-link",
                { active: currentActive.includes("/") },
              ])}
              to="/"
            >
              HOME
            </Link>
          </li>

          {/* <li className="nav-bar-item">
            <Link
              className={classNames([
                "nav-bar-link",
                { active: currentActive.includes("booking") },
              ])}
              to="/booking"
            >
              BOOKING
            </Link>
          </li> */}

          <li className="nav-bar-item">
            <Link
              className={classNames([
                "nav-bar-link",
                { active: currentActive.includes("contact-us") },
              ])}
              to="/contact-us"
            >
              CONTACT US
            </Link>
          </li>
        </ul>

        {/* CALL TO ACTION */}
        <ul className="nav-bar">
          <li className="nav-bar-item">
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<PhoneOutlined />}
                size={"middle"}
                href="tel:7606839999"
              >
                BOOK NOW
              </Button>
            </Space>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default DesktopMenu
