import React, { FC } from "react"

const MNIMembership: FC<{ memberId: string }> = ({ memberId }) => {
  React.useEffect(() => {
    new window.MNI.Widgets.Member(memberId, {
      member: 4574,
      styleTemplate:
        "#@id{text-align:center;position:relative}#@id .mn-widget-member-name{font-weight:700}#@id .mn-widget-member-logo{max-width:100%}",
    }).create()
  }, [])
  return <div id={memberId}></div>
}

export default MNIMembership
