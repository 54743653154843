import React, { FC, useCallback, useState, useEffect } from "react"
import { Drawer, Menu, Typography, Button } from "antd"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import _ from "lodash"
import classNames from "classnames"
import { PhoneOutlined } from "@ant-design/icons"

import "../styles/nav.scss"

// const { SubMenu } = Menu
const { Title, Paragraph } = Typography

const splitPathname = pathname => {
  if (pathname === "/") {
    return ["/"]
  }

  return _.drop(_.split(pathname, "/"))
}

const tuple = <T extends string[]>(...args: T) => args
const PlacementTypes = tuple("top", "right", "bottom", "left")
type placementType = typeof PlacementTypes[number]

const MobileMenu: FC<{
  placement: "top" | "bottom" | "right" | "left"
  isFixed: boolean
}> = ({ placement, isFixed }) => {
  const location = useLocation()

  const [currentActive, setCurrentActive] = useState(
    splitPathname(location.pathname)
  )
  const [visible, setVisible] = useState(false)

  const handleToggleMenu = useCallback(() => {
    setVisible(visible => !visible)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setVisible(false)
  }, [])

  useEffect(() => {
    if (isFixed) {
      const setMenuPosition = () => {
        if (window.pageYOffset > 20) {
          document
            .getElementById("mobile-nav-bar-container")
            .classList.add("position-fixed")
        } else {
          document
            .getElementById("mobile-nav-bar-container")
            .classList.remove("position-fixed")
        }
      }

      window.addEventListener("scroll", setMenuPosition)

      return () => {
        window.removeEventListener("scroll", setMenuPosition)
      }
    }
  }, [])

  return (
    <div id="mobile-nav-bar-container" className="shadow-sm">
      <Link to="/">
        <Title level={2} style={{ marginBottom: "0", paddingRight: "10px" }}>
          NAILS LOUNGE BY THE SEA
        </Title>
      </Link>

      <button
        className={classNames("hamburger hamburger--emphatic", {
          "is-active": visible,
        })}
        type="button"
        onClick={handleToggleMenu}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>

      <Drawer
        title={
          <>
            <Link to="/">
              <Title level={2} className="pr-2">
                NAILS LOUNGE BY THE SEA
              </Title>
              <Paragraph>Luxury Experience For Your Beauty</Paragraph>
            </Link>
            <Button
              type="primary"
              shape="round"
              icon={<PhoneOutlined />}
              size={"middle"}
              href="tel:760-683-9999"
            >
              BOOK NOW
            </Button>
          </>
        }
        placement={placement}
        onClose={handleCloseMenu}
        visible={visible}
        key={placement}
        height="auto"
      >
        <Menu
          style={{ width: "100%" }}
          selectedKeys={currentActive}
          mode="vertical"
        >
          <Menu.Item key="/">
            <Link to="/">HOME</Link>
          </Menu.Item>

          {/* <Menu.Item key="booking">
            <Link to="/booking">BOOKING</Link>
          </Menu.Item> */}

          <Menu.Item key="contact-us">
            <Link to="/contact-us">CONTACT</Link>
          </Menu.Item>

          {/* <SubMenu key="dropdown" title="DROP DOWN MENU ITEM">
            <Menu.Item key="5">Option 5</Menu.Item>
            <Menu.Item key="6">Option 6</Menu.Item>
            <SubMenu key="sub3" title="Submenu">
              <Menu.Item key="7">Option 7</Menu.Item>
              <Menu.Item key="8">Option 8</Menu.Item>
            </SubMenu>
          </SubMenu> */}
        </Menu>
      </Drawer>
    </div>
  )
}

export default MobileMenu
