/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { FC } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type MetaTagOne = {
  name: string
  content: string
}

type MetaTagTwo = {
  property: string
  content: string
}

const SEO: FC<{
  description?: string
  lang?: string
  meta?: Array<MetaTagOne | MetaTagTwo>
  title: string
}> = ({ description, lang = "en", meta = [], title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription: string = description || site.siteMetadata.description
  const defaultTitle: string = site.siteMetadata?.title

  const finalMeta: Array<MetaTagOne | MetaTagTwo> = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      {finalMeta &&
        finalMeta.map(item => {
          if ("name" in item) {
            return (
              <meta name={item.name} content={item.content} key={item.name} />
            )
          }

          return (
            <meta
              property={item.property}
              content={item.content}
              key={item.property}
            />
          )
        })}

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto:wght@300&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default SEO
