import React, { FC } from "react"
import { Layout, Typography, Row, Col, Space, Button } from "antd"

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons"
import { Yelp, Facebook, Instagram } from "@styled-icons/fa-brands"
import MNIMembership from "./MNIMembership"

const { Footer } = Layout
const { Paragraph, Title } = Typography

const FooterWrapper: FC<{}> = () => {
  return (
    <Footer className="shadow-sm bg-light">
      <Row align="middle" justify="space-between" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <a
            href="https://www.facebook.com/Nails-Lounge-By-The-Sea-104344111664193"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook size="42px" />
          </a>
          <a
            href="https://www.instagram.com/nailsloungebythesea/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram size="42px" />
          </a>
          <a
            href="https://www.yelp.com/biz/nails-lounge-by-the-sea-dana-point"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Yelp size="42px" />
          </a>
        </Col>

        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
          <Title level={3}>
            ©{new Date().getFullYear()} NAILS LOUNGE BY THE SEA
          </Title>
          <Paragraph>Proud Member Of Dana Point Chamber of Commerce</Paragraph>
        </Col>

        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <MNIMembership memberId="mni-membership-637498976251414134" />
        </Col>
      </Row>
    </Footer>
  )
}
export default FooterWrapper
