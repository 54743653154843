/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC, ReactNode } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Layout, Divider } from "antd"

import Header from "./header"
import Footer from "./footer"

const { Content } = Layout

const MainLayout: FC<{ children: ReactNode; isFixed?: boolean }> = ({
  children,
  isFixed,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Header isFixed={isFixed} />

      <Content style={{ backgroundColor: "white", padding: "0 0 2rem 0" }}>
        {children}
      </Content>

      <Footer />
    </Layout>
  )
}

export default MainLayout
